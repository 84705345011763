import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import Loader from './Loader';

const SelectDates = () => {
  const [selectedDates, setSelectedDates] = useState([]); // State to hold selected dates
  const [eventDetails, setEventDetails] = useState(null); // To store event details from Firestore
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date()); // For month navigation
  const navigate = useNavigate();
  const { eventName } = useParams(); // Get the event name from the URL

  // Fetch the event details from Firestore
  useEffect(() => {
    const fetchEventDetails = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const eventData = userDoc.data().events[eventName];

        setEventDetails(eventData);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventName]);

  // Helper function to check if a date is within the host's timeframe
const isDateWithinTimeframe = (date, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return date >= start && date <= end;
  };

  // Handle individual date selection
  const handleDateSelection = (date) => {
    const alreadySelected = selectedDates.some(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );
  
    const { startDate, endDate } = eventDetails.timeframe || {};
  
    if (startDate && endDate && !isDateWithinTimeframe(date, startDate, endDate)) {
      return; // Prevent selection outside the host's timeframe
    }
  
    if (alreadySelected) {
      // Remove the date if it's already selected
      setSelectedDates(selectedDates.filter((d) => d.getTime() !== date.getTime()));
    } else {
      // Add the date if it's not already selected
      setSelectedDates([...selectedDates, date]);
    }
  };
  

  // Submit selected dates to Firestore
  const handleDateSubmit = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      const userDoc = doc(db, 'users', user.uid);
      const eventRef = `events.${eventName}.selectedDates`;
  
      try {
        // Log selectedDates to ensure it's correctly filled
        console.log("Submitting dates:", selectedDates);
        await updateDoc(userDoc, {
          [eventRef]: arrayUnion(...selectedDates),
        });
        console.log("Firestore update successful");
  
        // Construct the share link after submission
        const uniqueLink = `/share-link/${user.uid}/${encodeURIComponent(eventName)}`;
        navigate(uniqueLink); // Navigate to the share link page
      } catch (error) {
        console.error("Error updating Firestore:", error);
      }
    }
  };
  
  
  

  // Move to the previous month
  const goToPreviousMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
  };

  // Move to the next month
  const goToNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
  };

  // Generate the dates for the current month, starting with Monday
  const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const firstDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // Adjust so that Monday is 0, Sunday is 6

  // Generate dates for the calendar view
  const datesInMonth = [];
  let date = new Date(firstDayOfMonth);
  date.setDate(date.getDate() - firstDayOfWeek);

  while (datesInMonth.length < 42) {
    datesInMonth.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  if (loading || !eventDetails) return <Loader />;;

  const hostDates =
    eventDetails?.timeframe?.startDate && eventDetails?.timeframe?.endDate ? (
      <p>
        Select dates within: {eventDetails.timeframe.startDate} -{' '}
        {eventDetails.timeframe.endDate}
      </p>
    ) : null;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
          Select Dates You're Free
        </h2>
        {hostDates} {/* Display host's suggested dates */}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 ring-1 ring-inset ring-gray-300">
          {/* Calendar Header */}
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={goToPreviousMonth}
              className="bg-gray-200 px-3 py-1 rounded-md text-gray-700"
            >
              Previous
            </button>
            <h3 className="text-lg font-semibold">
              {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </h3>
            <button
              onClick={goToNextMonth}
              className="bg-gray-200 px-3 py-1 rounded-md text-gray-700"
            >
              Next
            </button>
          </div>

          <div className="grid grid-cols-7 gap-2 mb-2">
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
              <div key={day} className="text-center font-bold text-gray-600">
                {day}
              </div>
            ))}
          </div>

          {/* Calendar grid */}
          <div className="grid grid-cols-7 gap-2">
            {datesInMonth.map((date) => {
    const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
    const { startDate, endDate } = eventDetails.timeframe || {};
    const isWithinTimeframe = startDate && endDate
      ? isDateWithinTimeframe(date, startDate, endDate)
      : true;
    const isSelected = selectedDates.some(
      (selectedDate) =>
        selectedDate.getDate() === date.getDate() &&
        selectedDate.getMonth() === date.getMonth() &&
        selectedDate.getFullYear() === date.getFullYear()
    );

    return (
      <div
        key={date.getTime()}
        className={`w-full h-10 flex justify-center items-center cursor-pointer 
          ${isSelected ? 'bg-blue-500 text-white' : 'bg-white'} 
          ${!isCurrentMonth ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : ''}
          ${!isWithinTimeframe ? 'bg-red-200 text-red-400 cursor-not-allowed' : ''}`}
        onClick={() => isCurrentMonth && isWithinTimeframe && handleDateSelection(date)} // Disable click for out-of-month or out-of-timeframe dates
      >
        {date.getDate()}
      </div>
              );
            })}
          </div>

          <div className="mt-4">
            <button
              onClick={handleDateSubmit}
              className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit Dates
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDates;
