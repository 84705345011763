import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { auth } from '../firebase'; // Assuming you are using Firebase Auth

const ShareLink = () => {
  // Use useParams to extract the uuid and eventName from the URL
  const { uuid, eventName } = useParams(); 

  // Get base URL
  const baseUrl = window.location.origin;

  // Encode only the event name
  const encodedEventName = encodeURIComponent(eventName);

  // Construct the correct URL with uuid and encoded event name
  const shareableURL = `${baseUrl}/availability?uuid=${uuid}&event=${encodedEventName}`;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
          Share Link With Friends
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Copy and share this link with your friends:
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 ring-1 ring-gray-300">
          <div className="mb-4">
            <input
              type="text"
              value={shareableURL}
              readOnly
              className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <button
            onClick={() => navigator.clipboard.writeText(shareableURL)}
            className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Copy Link
          </button>

          {/* Link to Dashboard */}
          <div className="mt-4 text-center">
            <Link
              to="/dashboard"
              className="text-indigo-600 hover:text-indigo-500 text-sm font-semibold"
            >
              Go to Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLink;
