import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth functions
import { Link } from 'react-router-dom'; // Assuming you're using React Router

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track if user is logged in
  const auth = getAuth();

  useEffect(() => {
    // Listen to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [auth]);

  const handleSignOut = () => {
    auth.signOut().then(() => {
      console.log("User signed out");
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  return (
    <header className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#EEEEEE] px-4 sm:px-10 py-3">
      <div className="flex items-center gap-4 text-black">
        {/* Burger Menu Icon */}
        
        <button onClick={toggleSidebar} className="text-black block sm:hidden">
          {isSidebarOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M128 116c7 0 12-6 12-13v-4h60a12 12 0 000-24H140v-4c0-7-5-13-12-13s-12 6-12 13v4H56a12 12 0 000 24h60v4c0 7 5 13 12 13zM56 184h144a12 12 0 000-24H56a12 12 0 000 24z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M224 128a8 8 0 01-8 8H40a8 8 0 010-16h176a8 8 0 018 8zm-8-56H40a8 8 0 010-16h176a8 8 0 010 16zm0 112H40a8 8 0 010-16h176a8 8 0 010 16z" />
            </svg>
          )}
        </button>
        <h2 className="text-black text-lg font-bold leading-tight tracking-[-0.015em]">Made It Out</h2>
      </div>
      <div className="flex flex-1 justify-end gap-4 sm:gap-4">
        <div className="flex items-center gap-9">
          {isLoggedIn ? (
            <Link className="text-black text-sm font-medium leading-normal hidden sm:block" to="/dashboard">
              Dashboard
            </Link>
          ) : (
            <Link className="text-black text-sm font-medium leading-normal" to="/login">
              Log In
            </Link>
          )}
        </div>

        {isLoggedIn ? (
          <button
            className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-black text-[#FFFFFF] text-sm font-bold leading-normal tracking-[0.015em]"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        ) : (
          <Link
            className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-black text-[#FFFFFF] text-sm leading-normal tracking-[0.015em]"
            to="/login"
          >
            Sign Up
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
