import React, { useState } from 'react';
import Header from './Header';
import { 
    CalendarIcon, 
    UsersIcon, 
    ZapIcon, 
    ArrowRightIcon, 
    CheckIcon, 
    ClockIcon, 
    LinkIcon, 
    MailIcon, 
    StarIcon, 
    UserGroupIcon, 
    CheckCircleIcon, 
    QuestionMarkCircleIcon 
  } from '@heroicons/react/24/outline';
  import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      // Save email to localStorage
      localStorage.setItem('emailForLogin', email);
      
      // Redirect to /login
      window.location.href = '/login';
    }
  };
  

  

  return (
    <div className="bg-white font-inter">
      <Header /> {/* Header component at the top */}

      {/* Hero Section */}
      <section className="relative bg-[#fff] pt-40 md:pt-30">
        <div className="container mx-auto px-4 text-center">
          <div className="flex flex-col md:justify-between items-center">
            <div className="md:w-1/2">
              <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">
              Organize Events, No More Stress
              </h1>
              <p className="mt-6 text-xl md:text-2xl text-gray-600">
                Plan events in just a few clicks and manage your time effortlessly. Set up your event and let your friends handle the rest!
              </p>
              <form onSubmit={handleSubmit} className="mt-8 flex flex-col md:flex-row justify-center gap-4 w-full mb-10">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="px-4 py-3 rounded-md border border-gray-300 text-lg w-full h-[45px]"
                />
                <button type="submit" className="bg-black text-white px-4 md:py-2 py-3 rounded-md shadow-lg text-lg font-medium w-full md:w-1/3" onClick={handleSubmit}>
                  Get Started
                </button>
              </form>
            </div>
            <div className="mt-10 md:mt-0 rounded-lg w-full sm:w-1/4">
              <img
                src="/desktop3.png"
                alt="Dashboard Mockup"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-20 bg-[#1c1c1c]">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white">How It Works</h2>
            <p className="mt-4 text-white">Create, Share, and Plan – it’s that simple.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <CalendarIcon className="mx-auto w-16 h-16 text-white mb-4" />
              <h3 className="font-bold text-xl text-white">Create Event</h3>
              <p className="mt-2 text-white">Select dates and times, and set up your event in seconds.</p>
            </div>
            <div className="text-center">
              <UserGroupIcon className="mx-auto w-16 h-16 text-white mb-4" />
              <h3 className="font-bold text-xl text-white">Invite Friends</h3>
              <p className="mt-2 text-white">Share a link with your friends and gather their availability.</p>
            </div>
            <div className="text-center">
              <CheckCircleIcon className="mx-auto w-16 h-16 text-white mb-4" />
              <h3 className="font-bold text-xl text-white">Select Best Date</h3>
              <p className="mt-2 text-white">The app will suggest the best date based on everyone’s availability.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Social Proof / Testimonials Section */}
      <section className="py-20 bg-[#eeeeee]">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-black">What People Are Saying</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
            {[
              { name: "Emily P.", quote: "This app made organizing our group trip so easy! I don’t know how we did it before." },
              { name: "Jacob M.", quote: "I can finally stop scrolling through endless messages to find out when everyone’s free." },
              { name: "Sarah W.", quote: "Super easy to use! I love that it keeps everything organized in one place." }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                <h4 className="mt-4 font-bold text-gray-900">- {testimonial.name}</h4>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900">Frequently Asked Questions</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                question: "How long does it take to set up an event?",
                answer: "With Made It Out, you can create an event and send invitations in as little as 1-2 minutes."
              },
              {
                question: "Can I use Made It Out on my mobile device?",
                answer: "Yes, Made It Out is fully responsive and works great on both desktop and mobile devices."
              },
              {
                question: "Do my friends need an account to respond?",
                answer: "No, your friends can respond to event invitations without needing an account."
              },
              {
                question: "How much does it cost?",
                answer: "You can start using Made It Out for free with no credit card required. Premium features are available after a 14-day trial."
              }
            ].map((faq, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md flex items-start">
                <QuestionMarkCircleIcon className="w-8 h-8 text-teal-600 flex-shrink-0 mr-4" />
                <div>
                  <h3 className="font-bold text-xl text-gray-900">{faq.question}</h3>
                  <p className="text-gray-600 mt-2">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="bg-[#000] py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl text-white font-bold mb-4">Ready to Get Started?</h2>
          <Link to="/login">
  <button className="bg-white px-8 py-3 rounded-full font-medium shadow-lg">
    Sign Up Now
  </button>
</Link>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-[#fcfcfc] py-6">
        <div className="container mx-auto text-center">
          <p>&copy; {new Date().getFullYear()} Made It Out. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
