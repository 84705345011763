import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const CreateEvent = ({ onClose, onEventCreated, onBackToDashboard }) => {
  const [name, setName] = useState('');
  const [eventName, setEventName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();

  const handleEventCreate = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const existingEvents = userData.events || {};
  
        const newEvent = {
          eventName,
          timeframe: {
            startDate,
            endDate,
          },
          selectedDates: [],
          friends: {},
          createdAt: new Date(),
          name: name,
          uid: user.uid
        };
  
        await updateDoc(userDocRef, {
          [`events.${eventName}`]: newEvent,
        });
  
        alert('Event created successfully!');
  
        if (onEventCreated) {
          onEventCreated(eventName);
        } else {
          navigate(`/select-dates/${user.uid}/${encodeURIComponent(eventName)}`);
        }
  
        if (onClose) onClose();
      }
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
      {/* X Icon at the top right for returning to dashboard */}
      <button 
        onClick={onBackToDashboard} 
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-900">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
          Create an Event
        </h2>
        <p className="mt-2 text-center text-sm leading-6 text-gray-600">
          Enter your event details to get started.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 ring-1 ring-inset ring-gray-300">
          <form className="space-y-6">
            {/* Name Input */}
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Your Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="block w-full rounded-md border border-gray-300 py-1.5 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Jane Doe"
                />
              </div>
            </div>

            {/* Event Name Input */}
            <div>
              <label
                htmlFor="event-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Event Name
              </label>
              <div className="mt-2">
                <input
                  id="event-name"
                  name="event-name"
                  type="text"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                  required
                  className="block w-full rounded-md border border-gray-300 py-1.5 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Holiday Party"
                />
              </div>
            </div>

            {/* Start Date Input */}
            <div>
              <label
                htmlFor="start-date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Start Date
              </label>
              <div className="mt-2">
                <input
                  id="start-date"
                  name="start-date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                  className="block w-full rounded-md border border-gray-300 py-1.5 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* End Date Input */}
            <div>
              <label
                htmlFor="end-date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                End Date
              </label>
              <div className="mt-2">
                <input
                  id="end-date"
                  name="end-date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                  className="block w-full rounded-md border border-gray-300 py-1.5 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="button"
                onClick={handleEventCreate}
                className="w-full rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#555555] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Create Event
              </button>
            </div>
          </form>


        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
