import React, { useState } from 'react';
import CalendarWithInvitees from './CalendarWithInvitees';
import { getAuth } from 'firebase/auth';

const auth = getAuth();
const user = auth.currentUser;

const formatDate = (date) => {
  if (!date) return 'Invalid Date';

  let parsedDate;
  if (typeof date === 'object' && date.seconds) {
    parsedDate = new Date(date.seconds * 1000); // Firebase Timestamp to JS Date
  } else if (typeof date === 'string') {
    parsedDate = new Date(date);
  }

  if (isNaN(parsedDate)) return 'Invalid Date';

  const options = { day: 'numeric', month: 'short' };
  return parsedDate.toLocaleDateString('en-GB', options);
};

// Helper function to check if dates are common between host and all invitees
const findBestDates = (hostDates, invitees) => {
  const inviteeDatesArray = Object.values(invitees).map(invitee =>
    invitee.dates.map(date => date.seconds ? new Date(date.seconds * 1000).toISOString() : date)
  );

  const allDates = [hostDates.map(date => date.seconds ? new Date(date.seconds * 1000).toISOString() : date), ...inviteeDatesArray];

  const dateCount = allDates.flat().reduce((acc, date) => {
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  if (Object.keys(dateCount).length === 0) {
    return { bestDates: [], allCanMakeIt: false };
  }

  let bestDates = Object.entries(dateCount).filter(([date, count]) => count === allDates.length).map(([date]) => date);

  bestDates = bestDates.sort((a, b) => new Date(a) - new Date(b));

  if (bestDates.length > 0) {
    return { bestDates, allCanMakeIt: true };
  } else {
    const mostCommonDate = Object.entries(dateCount).reduce((a, b) => (a[1] > b[1] ? a : b), [null, 0]);
    return { bestDates: [mostCommonDate[0]], allCanMakeIt: false };
  }
};

// Component to display the best date or most common date
const getBestDateMessage = (bestDates, allCanMakeIt, invitees) => {
  if (!invitees || Object.keys(invitees).length === 0) {
    return (
      <div className="bg-gray-100 rounded-lg shadow-md p-4">
        <p className="text-gray-800">No invitees have selected any dates yet.</p>
      </div>
    );
  }

  const formattedDates = bestDates.map(date => formatDate(date)).join(', ');

  if (allCanMakeIt) {
    return (
      <div className="bg-green-100 rounded-lg shadow-md p-4">
        <p className="text-green-800 font-bold">Best Dates:</p>
        <p className="text-green-800">Everyone can make <strong>{formattedDates}</strong></p>
      </div>
    );
  } else {
    return (
      <div className="bg-blue-100 rounded-lg shadow-md p-4">
        <p className="text-blue-800 font-bold">Most People Can Make:</p>
        <p className="text-blue-800">Most people can do <strong>{formattedDates}</strong></p>
      </div>
    );
  }
};

const EventDetails = ({ backToEventList, selectedEventDetails }) => {
  const [isCopied, setIsCopied] = useState(false);

  const hostDates = selectedEventDetails.selectedDates || [];
  const invitees = selectedEventDetails.friends || {};
  const hostEmail = selectedEventDetails.email || '';  // Fetch the host email

  // Use the helper function to find the best dates
  const { bestDates, allCanMakeIt } = findBestDates(hostDates, invitees);

  // Copy the share link to clipboard
  const copyToClipboard = () => {
    const user = auth.currentUser;
    
    if (!user) {
      console.error("User not authenticated");
      return;
    }
    
    const shareLink = `${window.location.origin}/availability?uuid=${user.uid}&event=${encodeURIComponent(selectedEventDetails.eventName)}`;
    navigator.clipboard.writeText(shareLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
  };
  

  return (
    <div>
      <div className="flex items-center gap-3 px-4 py-2">
        <button className="text-[#575757] text-sm mb-2" onClick={backToEventList}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
        </button>
      </div>
      <div className="sm:px-4 sm:py-6">
        <div className='px-4 sm:px-0'>
          <h2 className="text-black text-3xl font-bold leading-tight flex items-center">
            {selectedEventDetails.eventName}
            <button onClick={copyToClipboard} className="ml-3 text-md text-gray-600 hover:text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
              </svg>
            </button>
          </h2>
          {isCopied && <p className="text-green-500">Link copied!</p>}

          {formatDate(selectedEventDetails?.timeframe.startDate) + ' - ' + formatDate(selectedEventDetails?.timeframe.endDate)}

          {/* Best Date Message */}
          <div className="my-4">
            {getBestDateMessage(bestDates, allCanMakeIt, invitees)}
          </div>
        </div>

        <CalendarWithInvitees
          hostName={selectedEventDetails.name}
          hostDates={selectedEventDetails.selectedDates}
          invitees={selectedEventDetails.friends || {}}
          hostEmail={hostEmail} 
        />
      </div>
    </div>
  );
};

export default EventDetails;
