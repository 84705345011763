import React, { useEffect, useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Header from './Header';
import Sidebar from './Sidebar';
import EventList from './EventList';
import EventDetails from './EventDetails';
import CreateEvent from './CreateEvent';

const Dashboard = () => {
  const [events, setEvents] = useState({});
  const [expandedEvent, setExpandedEvent] = useState(null); 
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); 
  const [activeView, setActiveView] = useState('dashboard'); // Default view is 'dashboard'
  const [friendsInvited, setFriendsInvited] = useState(0); // Mock value for now

  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEvents(data.events || {});
          setFriendsInvited(Object.keys(data.friends || {}).length); // Assuming there's a 'friends' key in the user data
        }
      }
    };
    fetchEvents();
  }, []);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const toggleEvent = (eventName) => {
    setExpandedEvent(expandedEvent === eventName ? null : eventName);
  };

  const showCreateEvent = () => {
    setActiveView('createEvent'); // Switch view to CreateEvent
  };

  const cancelCreateEvent = () => {
    setActiveView('events'); // Return to EventList view
  };

  const backToDashboard = () => {
    setActiveView('dashboard'); // Reset view to dashboard
    setExpandedEvent(null); // Clear any expanded event
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  // Example goal completion calculation (you can modify this based on your app's logic)
  const eventsCreated = Object.keys(events).length;
  const eventsGoal = 20;
  const eventsProgress = (eventsCreated / eventsGoal) * 100;

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-[#FFFFFF] group/design-root overflow-x-hidden overflow-y-auto">
      <Header handleSignOut={handleSignOut} toggleSidebar={toggleSidebar} />
      <div className={`layout-container flex h-full grow flex-col ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
        <div className="gap-1 px-0 sm:px-6 flex flex-1 justify-center py-5">
          <div className={`absolute top-65 left-0 h-full w-[400px] z-10 bg-white transition-transform transform ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:relative md:top-auto md:left-auto`}>
            <Sidebar 
              activeView={activeView} // Pass activeView to Sidebar
              onShowEvents={() => setActiveView('events')} // Set active view when events are clicked
              onShowDashboard={() => setActiveView('dashboard')} // Set active view for dashboard
            />
          </div>

          <div className="layout-content-container flex flex-col max-w-[960px] flex-1 w-full">
            {activeView === 'createEvent' ? (
              <CreateEvent onCancel={cancelCreateEvent} onBackToDashboard={backToDashboard} />
            ) : expandedEvent ? (
              <EventDetails 
                backToEventList={() => setExpandedEvent(null)} 
                selectedEventDetails={events[expandedEvent] || {}} 
              />
            ) : activeView === 'dashboard' ? (
              <div className="dashboard-section space-y-6">
                {/* Announcements Banner */}
                <div className="announcement-banner bg-blue-100 text-blue-700 p-4 rounded-lg shadow mx-4">
                  🎉 <span className='font-bold'>New Feature Released:</span> You can now send Google Calendar invitations straight from your event!
                </div>

                {/* Goals & User Stats Section */}
                <div className="p-4">
                  <h2 className="text-black text-4xl font-black leading-tight">Stats & Goals</h2>
                  <p className="text-[#6B6B6B] font-normal leading-normal mt-2 mx-1">
                    Keep going! You're nearly there.
                  </p>
                  <div className="space-y-4 mt-4">
                    <div className="p-4 bg-gray-100 rounded-lg shadow">
                      <h3 className="text-lg font-semibold">✨ Events Created</h3>
                      <p className="text-sm">{eventsCreated} out of {eventsGoal} events created</p>
                      <div className="w-full bg-gray-300 rounded-full h-2 mt-2">
                        <div className="bg-blue-500 h-2 rounded-full" style={{ width: `${eventsProgress}%` }}></div>
                      </div>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg shadow">
                      <h3 className="text-lg font-semibold">🤝 Friends Invited</h3>
                      <p className="text-sm">{friendsInvited} friends invited</p>
                    </div>
                  </div>
                </div>

                {/* Upcoming Updates Section */}
                <div className="p-4">
                  <h2 className="text-black text-4xl font-black leading-tight">Upcoming Updates</h2>
                  <p className="text-[#6B6B6B] font-normal leading-normal mt-2 mx-1">
                    See what we're currently working on!
                  </p>
                  <div className="space-y-4 mt-4">
                    <div className="p-4 bg-gray-100 rounded-lg shadow">
                      <h3 className="text-lg font-semibold">Feature: Event Notifications</h3>
                      <p className="text-sm">You'll receive notifications when friends select their availability.</p>
                      <p className="text-xs text-gray-500 mt-1">Coming Soon</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg shadow">
                      <h3 className="text-lg font-semibold">Feature: Calendar Integration</h3>
                      <p className="text-sm">Sync your events with Google Calendar & invite your friends.</p>
                      <p className="text-xs text-gray-500 mt-1">Coming Soon</p>
                    </div>
                  </div>
                </div>

                {/* Events Listing */}
                <div>
                  <EventList 
                    events={events} 
                    toggleEvent={toggleEvent} 
                    onCreateEvent={showCreateEvent} 
                  />
                </div>

                

                

                {/* User Feedback Section */}
                <div className="p-4">
                  <h2 className="text-black text-4xl font-black leading-tight">Give Us Feedback</h2>
                  <p className="text-[#6B6B6B] font-normal leading-normal mt-2 mx-1">
                    Your feedback helps shape the future of the app! Let us know about any bugs or features you’d like to see.
                  </p>
                  <form className="space-y-4 mt-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Feedback Type</label>
                      <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm">
                        <option>Bug</option>
                        <option>Feature Request</option>
                        <option>Other</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Description</label>
                      <textarea className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm" rows="4"></textarea>
                    </div>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg">Submit Feedback</button>
                  </form>
                </div>
              </div>
            ) : (
              <EventList 
                events={events} 
                toggleEvent={toggleEvent} 
                onCreateEvent={showCreateEvent} 
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
