import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

const Availability = () => {
  const [searchParams] = useSearchParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [friendName, setFriendName] = useState('');
  const [friendEmail, setFriendEmail] = useState(''); // Added state for email
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateCounts, setDateCounts] = useState({});
  const [hostDates, setHostDates] = useState([]);
  const [hostName, setHostName] = useState('');
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [timeframe, setTimeframe] = useState(null); // State for the timeframe (start and end dates)

  const uuid = searchParams.get('uuid');
  const eventName = searchParams.get('event');

  // Utility function to check if two dates are equal (ignoring time)
  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const docRef = doc(db, 'users', uuid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setHostName(userData.events[eventName].name);
          const event = userData.events[eventName];

          if (event) {
            setEventDetails(event);
            setHostDates(event.selectedDates);

            const timeframe = event.timeframe;
            if (timeframe) {
              const start = new Date(timeframe.startDate);
              const end = new Date(timeframe.endDate);

              // Log timeframe for debugging
              console.log('Timeframe Start:', start);
              console.log('Timeframe End:', end);

              setTimeframe({
                start,
                end,
              });
            }

            const allSelectedDates = calculateDateCounts(event);
            setDateCounts(allSelectedDates);
          } else {
            setError('Event not found');
          }
        } else {
          setError('No such user found');
        }
      } catch (err) {
        setError('Failed to fetch event data');
        console.error('Error fetching event:', err);
      } finally {
        setLoading(false);
      }
    };

    if (uuid && eventName) {
      fetchEventDetails();
    } else {
      setError('Invalid URL parameters');
      setLoading(false);
    }
  }, [uuid, eventName]);

  const calculateDateCounts = (event) => {
    const dateMap = {};

    event.selectedDates.forEach((date) => {
      const dateString = new Date(date.seconds * 1000).toLocaleDateString();
      dateMap[dateString] = (dateMap[dateString] || 0) + 1;
    });

    if (event.friends) {
      Object.values(event.friends).forEach((friend) => {
        friend.dates.forEach((date) => {
          const dateString = new Date(date.seconds * 1000).toLocaleDateString();
          dateMap[dateString] = (dateMap[dateString] || 0) + 1;
        });
      });
    }

    return dateMap;
  };

  const isDateInHostRange = (date) => {
    if (!timeframe) return false;

    const { start, end } = timeframe;
    return date >= start && date <= end;
  };

  const handleDateSelection = (date) => {
    if (selectedDates.some((selectedDate) => isSameDay(selectedDate, date))) {
      setSelectedDates(selectedDates.filter((selectedDate) => !isSameDay(selectedDate, date)));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const getDateColor = (count) => {
    if (count === 1) return 'bg-heat-1';
    if (count === 2) return 'bg-heat-2';
    if (count === 3) return 'bg-heat-3';
    if (count === 4) return 'bg-heat-4';
    if (count >= 5) return 'bg-heat-5';
    return '';
  };

  const highlightSelectedDates = (date) => {
    const dateString = date.toLocaleDateString();
    const userSelected = selectedDates.some((selectedDate) => selectedDate.getTime() === date.getTime());
    if (userSelected) return 'bg-blue-500 text-white';
    const count = dateCounts[dateString] || 0;
    return getDateColor(count);
  };

  const generateDatesForMonth = () => {
    const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
    const dates = [];

    for (let day = 1; day <= lastDay.getDate(); day++) {
      dates.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day));
    }

    return dates;
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
  };

  const goToNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
  };

  const handleSubmit = async () => {
    if (!friendName || !friendEmail || selectedDates.length === 0) { // Added email check
      setError('Please enter your name, email, and select at least one date.');
      return;
    }

    try {
      const docRef = doc(db, 'users', uuid);
      const friendData = selectedDates.map((date) => Timestamp.fromDate(date));

      // Save name, selected dates, and email to Firestore
      await updateDoc(docRef, {
        [`events.${eventName}.friends.${friendName}`]: {
          dates: friendData,
          email: friendEmail, // Added email
        },
      });

      alert('Your availability has been saved!');
    } catch (err) {
      setError('Failed to save data. Please try again.');
    }
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const firstDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7;

  const datesInMonth = [];
  let date = new Date(firstDayOfMonth);
  date.setDate(date.getDate() - firstDayOfWeek);

  while (datesInMonth.length < 42) {
    datesInMonth.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
          Hey! {hostName} has invited you to {eventName}
        </h2>
        <p className="mt-2 text-center text-sm leading-6 text-gray-500">
          Please select dates you are free!
        </p>

        <p className="mt-4 text-center text-sm leading-6 text-gray-700">
          Your host has suggested these dates: {hostDates.map(date => new Date(date.seconds * 1000).toLocaleDateString()).join(', ')}
        </p>

        <h3 className="text-xl font-bold mt-6 text-center text-gray-900">Select Your Free Dates</h3>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 ring-1 ring-inset ring-gray-300">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={goToPreviousMonth}
              className="bg-gray-200 px-3 py-1 rounded-md text-gray-700"
            >
              Previous
            </button>
            <h3 className="text-lg font-semibold">
              {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </h3>
            <button
              onClick={goToNextMonth}
              className="bg-gray-200 px-3 py-1 rounded-md text-gray-700"
            >
              Next
            </button>
          </div>

          <div className="grid grid-cols-7 gap-2 mb-4">
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
              <div
                key={day}
                className="text-center font-bold text-gray-600"
              >
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-2">
            {datesInMonth.map((date) => {
                const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
                const isSelected = selectedDates.some(
                    (selectedDate) => selectedDate.getDate() === date.getDate() &&
                    selectedDate.getMonth() === date.getMonth() &&
                    selectedDate.getFullYear() === date.getFullYear()
                );

                // Check if the date is within the host's suggested range
                const isWithinHostRange = isDateInHostRange(date);

                return (
                    <div
                        key={date.getTime()}
                        className={`h-10 flex justify-center items-center cursor-pointer w-full ${
                            highlightSelectedDates(date)
                        } ${
                            isSelected ? 'bg-blue-500 text-white' : 'bg-white'
                        } ${
                            !isCurrentMonth || !isWithinHostRange ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : ''
                        }`}
                        onClick={() => isCurrentMonth && isWithinHostRange && handleDateSelection(date)} // Only allow selection if within host's range
                    >
                        {date.getDate()}
                    </div>
              );
            })}
          </div>

          <div className="mt-4">
            <input
              type="text"
              placeholder="Your Name"
              value={friendName}
              onChange={(e) => setFriendName(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />

            {/* Added Friend's Email Input */}
            <input
              type="email"
              placeholder="Your Email"
              value={friendEmail}
              onChange={(e) => setFriendEmail(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 mt-4"
            />

            <button
              onClick={handleSubmit}
              className="mt-4 w-full rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Submit Dates
            </button>
          </div>

          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Availability;
