import React from 'react';
import { HomeIcon, CalendarIcon, UsersIcon, UserIcon, LightBulbIcon } from '@heroicons/react/24/outline';

const Sidebar = ({ activeView, onShowEvents, onShowDashboard }) => {
  return (
    <div className="layout-content-container p-4 flex flex-col w-80">
      <div className="flex flex-col gap-4">
        <div className="flex gap-3">
          <div
            className="bg-center bg-no-repeat aspect-square bg-cover rounded-full size-10 bg-[#e2e2e2]"
            style={{ backgroundImage: 'url("https://your-profile-picture-url")' }}
          ></div>
          <div className="flex flex-col">
            <h1 className="text-black text-base font-medium leading-normal">Made It Out</h1>
            <p className="text-[#6B6B6B] text-sm font-normal leading-normal">Dashboard</p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {/* Dashboard */}
          <div 
            className={`flex items-center gap-3 px-3 py-2 rounded-full cursor-pointer ${
              activeView === 'dashboard' ? 'bg-gray-200' : 'hover:bg-gray-100'
            }`} 
            onClick={onShowDashboard}
          >
            <HomeIcon className="w-6 h-6 text-black" />
            <p className="text-black text-sm font-medium leading-normal">Dashboard</p>
          </div>

          {/* Events */}
          <div 
            className={`flex items-center gap-3 px-3 py-2 rounded-full cursor-pointer ${
              activeView === 'events' ? 'bg-gray-200' : 'hover:bg-gray-100'
            }`} 
            onClick={onShowEvents}
          >
            <CalendarIcon className="w-6 h-6 text-black" />
            <p className="text-black text-sm font-medium leading-normal">Events</p>
          </div>

          {/* Ideas (Disabled) */}
          <div className="flex items-center gap-3 px-3 py-2 rounded-full opacity-50 cursor-not-allowed pointer-events-none">
            <LightBulbIcon className="w-6 h-6 text-gray-400" />
            <p className="text-gray-400 text-sm font-medium leading-normal">Ideas (Coming soon)</p>
          </div>

          {/* Friends (Disabled) */}
          <div className="flex items-center gap-3 px-3 py-2 rounded-full opacity-50 cursor-not-allowed pointer-events-none">
            <UsersIcon className="w-6 h-6 text-gray-400" />
            <p className="text-gray-400 text-sm font-medium leading-normal">Friends (Coming soon)</p>
          </div>

          

          {/* My Account (Disabled) */}
          <div className="flex items-center gap-3 px-3 py-2 rounded-full opacity-50 cursor-not-allowed pointer-events-none">
            <UserIcon className="w-6 h-6 text-gray-400" />
            <p className="text-gray-400 text-sm font-medium leading-normal">My Account (Coming soon)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
