import React from 'react';

const EventList = ({ events, toggleEvent, onCreateEvent }) => {
  const formatDate = (date) => {
    if (!date) return 'Invalid Date';

    let parsedDate;
    if (typeof date === 'object' && date.seconds) {
      parsedDate = new Date(date.seconds * 1000);
    } else if (typeof date === 'string') {
      parsedDate = new Date(date);
    }

    if (isNaN(parsedDate)) return 'Invalid Date';

    const options = { day: 'numeric', month: 'short' };
    return parsedDate.toLocaleDateString('en-GB', options);
  };

  const getEmojiForEvent = (eventName) => {
    const emojiMap = {
      golf: '⛳',
      birthday: '🎂',
      meeting: '📅',
      movie: '🎥',
      party: '🎉',
      soccer: '⚽',
      basketball: '🏀',
      tennis: '🎾',
      baseball: '⚾',
      running: '🏃‍♂️',
      cycling: '🚴‍♀️',
      swimming: '🏊‍♀️',
      hiking: '🥾',
      camping: '🏕️',
      beach: '🏖️',
      skiing: '🎿',
      snowboarding: '🏂',
      fishing: '🎣',
      concert: '🎸',
      music: '🎵',
      dance: '💃',
      shopping: '🛍️',
      dinner: '🍽️',
      lunch: '🍴',
      breakfast: '🥞',
      coffee: '☕',
      travel: '✈️',
      train: '🚆',
      gym: '🏋️‍♀️',
      yoga: '🧘‍♂️',
      workout: '💪',
      spa: '💆‍♀️',
      haircut: '✂️',
      gaming: '🎮',
      reading: '📚',
      studying: '📖',
      work: '💼',
      football: '🏈',
      volleyball: '🏐',
      bowling: '🎳',
      chess: '♟️',
      puzzle: '🧩',
      art: '🎨',
      writing: '✍️',
      gardening: '🌻',
      picnic: '🍉',
      barbecue: '🍖',
      photography: '📷',
      coding: '💻',
      volunteering: '🤝',
      wedding: '💍',
      meditation: '🧘',
      cleaning: '🧹',
      pets: '🐾',
      zoo: '🦁',
      boardGames: '🎲',
      karaoke: '🎤',
      cooking: '👨‍🍳',
      theater: '🎭',
      fireworks: '🎆',
      iceSkating: '⛸️',
      rollerSkating: '🛼',
      bookClub: '📚',
      golfing: '⛳',
      footballMatch: '⚽',
      tennisMatch: '🎾',
      rugby: '🏉',
      carShow: '🚗',
      roadTrip: '🚗',
      sightseeing: '🗺️',
      airplane: '✈️',
      library: '🏫',
      painting: '🖌️',
      quizNight: '❓',
      space: '🚀',
      escapeRoom: '🕵️‍♂️',
      brewery: '🍺',
      wineTasting: '🍷',
      meal: '🍕',
      clubbing: '🍾',
      horse: '🐎',
      holiday: '🏖️',
      dog: '🐕'
    };

    const foundKey = Object.keys(emojiMap).find((key) =>
      eventName.toLowerCase().includes(key)
    );

    return foundKey ? emojiMap[foundKey] : '🗓️'; // Default emoji if none found
  };

  return (
    <div className="flex flex-col justify-between gap-2 sm:p-4">
      <div className="flex justify-between items-center px-4 pb-4">
        <div>
          <p className="text-black text-4xl font-black leading-tight">Your Events</p>
          <p className="text-[#6B6B6B] text-base font-normal">Manage and view your upcoming events</p>
        </div>
        <button 
          onClick={onCreateEvent} 
          className="flex items-center px-4 py-2 bg-black text-white rounded-full">
          <span className="mr-2 hidden sm:block">Create Event</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>

      {Object.entries(events)
        .sort(([aName, aDetails], [bName, bDetails]) => {
            const aCreatedAt = aDetails?.createdAt?.seconds || 0;
            const bCreatedAt = bDetails?.createdAt?.seconds || 0;
            return bCreatedAt - aCreatedAt;
          })
        .map(([eventName, eventDetails]) => (
          <div key={eventName} className="flex items-center gap-4 bg-[#FFFFFF] px-4 min-h-[72px] py-0 justify-between cursor-pointer sm:pl-0" onClick={() => toggleEvent(eventName)}>
            <div className="flex items-center gap-4">
              <div className="text-black flex items-center justify-center rounded-lg bg-none text-[2rem] shrink-0 size-12">
                {getEmojiForEvent(eventName)}
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-black text-base font-medium leading-normal line-clamp-1">{eventName}</p>
                <p className="text-[#6B6B6B] text-sm font-normal leading-normal line-clamp-2">
                  {'Friends: ' + Object.entries(eventDetails.friends).length} | {formatDate(eventDetails?.timeframe.startDate) + ' - ' + formatDate(eventDetails?.timeframe.endDate)}
                </p>
              </div>
            </div>
            <div className="shrink-0">
              <div className="text-black flex size-7 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                </svg>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default EventList;
